"use client"; // Error components must be Client Components

import { PublicContainer } from "@cakemembers/components-cake";
import { Heading3, Heading4 } from "@cakemembers/components-core";
import { useEffect } from "react";
import { useAuth } from "@clerk/nextjs";
import * as Sentry from "@sentry/nextjs";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const { userId, isLoaded } = useAuth();
  useEffect(() => {
    // Log the error to an error reporting service
    if (isLoaded) {
      if (userId) {
        Sentry.setUser({ id: userId });
      }
      Sentry.captureException(error);
    }
  }, [error, isLoaded, userId]);

  return (
    <PublicContainer centerVertically>
      <Heading4 className="text-xl text-center mb-4">
        Application error: a server side
        <br /> exception has occurred
      </Heading4>
      {error.digest && <Heading3>Digest: {error.digest}</Heading3>}
    </PublicContainer>
  );
}
